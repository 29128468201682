import React from 'react';
import { auth, provider } from '../firebase/firebase';
import { signInWithPopup } from "firebase/auth";
import { checkKindshipEmail } from './check_kindship_email';

const SignInWithGoogle = () => {
  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, provider);

      // Extract Email
      if(res?.user?.email) {
        console.log(res.user.email);
        // Check For Kindship Email
        const email = res.user.email;
        const isKindshipWorkspaceEmail = checkKindshipEmail(email);

        if(isKindshipWorkspaceEmail) {
          console.log('Kindship Email')
        } else {
          auth.signOut();
        }
      }

      console.log(res);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <button onClick={signInWithGoogle}>
      Sign In with Google
    </button>
  );
};

export default SignInWithGoogle;
