import React from 'react'
import SignInWithGoogle from '../../signinwithgoogle/sign_in_with_google'

const LoginPage = () => {
  return (
    <>
    <SignInWithGoogle />
    </>
  )
}

export default LoginPage