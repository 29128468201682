import React, { useEffect, useState } from "react";
import { Route, redirect, Routes, Navigate } from "react-router-dom";
import ParentDashboardPage from "./components/parent-dashboard/ParentDashboardPage";
import LoginPage from "./components/login/LoginPage";
import HomePage from "./components/HomePage";
import IntercomMerge from "./components/intercom/merge";
import { auth } from "./firebase/firebase";
import AddParent from "./components/parent/add-parent";

const MainApp = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
      setLoading(false);
    });
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={isSignedIn ? <HomePage /> : <Navigate replace to="/login" />}
        />
        <Route
          path="/login"
          element={!isSignedIn ? <LoginPage /> : <Navigate replace to="/" />}
        />
        {/* <Route path="/parent/:docId" element={isSignedIn ? <ParentDashboardPage /> : <Navigate replace to="/login" />} /> */}
        <Route
          path="/addparent"
          element={
            isSignedIn ? <AddParent /> : <Navigate replace to="/login" />
          }
        />
        <Route
          path="/intercom-merge"
          element={
            isSignedIn ? <IntercomMerge /> : <Navigate replace to="/login" />
          }
        />
      </Routes>
    </div>
  );
};

export default MainApp;
