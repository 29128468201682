import { Snackbar } from '@mui/material';
import React, { createContext, useContext, useState, useCallback } from 'react';

const SnackbarContext = createContext();

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
  });

  const openSnackbar = useCallback((message) => {
    setSnackbarState({ open: true, message });
  }, []);

  const closeSnackbar = useCallback(() => {
    setSnackbarState({ open: false, message: '' });
  }, []);

  

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}
      {/* Your Snackbar component will go here */}
      <Snackbar
  open={snackbarState.open}
  autoHideDuration={6000}
  onClose={closeSnackbar}
  message={snackbarState.message}
/>
    </SnackbarContext.Provider>
  );
};


