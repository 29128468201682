import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Chip } from "@mui/material";
import { blueGrey, grey, orange } from "@mui/material/colors";

const columns = [
  { id: "name", label: "Name", minWidth: 150 },
  { id: "role", label: "Role", minWidth: 150 },
  { id: "phone", label: "Phone", minWidth: 100 },
  {
    id: "email",
    label: "Email",
    minWidth: 150,
  },
  {
    id: "journey",
    label: "Journey",
    minWidth: 100,
    group: "custom_attributes",
  },
  {
    id: "nickname",
    label: "Nickname",
    minWidth: 100,
    group: "custom_attributes",
  },
  {
    id: "plan_managed",
    label: "Plan Managed",
    minWidth: 100,
    group: "custom_attributes",
  },
  {
    id: "ndis_plan_1",
    label: "Ndis Plan",
    maxWidth: 300,
    group: "custom_attributes",
  },
];

export default function StickyHeadTable({ rows, close, selected }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [active, setActive] = React.useState(null);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const activeHandler = (id) => {
    setActive(id);
    selected(id);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell
                  sx={{ background: blueGrey[800], color: "#fff" }}
                  key={idx}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, idx) => {
                  return (
                    <TableRow
                      onClick={() => activeHandler(row.id)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value =
                          column.group === "custom_attributes"
                            ? row[column.group][column.id]
                            : row[column.id];
                        return (
                          <TableCell
                            sx={{
                              background:
                                active === row.id
                                  ? orange[800]
                                  : idx % 2 === 0
                                    ? blueGrey[300]
                                    : blueGrey[400],
                              color: grey[900],
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            {!rows.length && (
              <Chip
                label="Oops empty result!"
                sx={{ marginX: "auto", marginY: 4 }}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ padding: 1 }}>
        <Button onClick={close} variant="contained" sx={{ marginRight: 2 }}>
          Confirm
        </Button>
        <Button
          onClick={() => {
            selected(null);
            setActive(null);
            close();
          }}
        >
          Cancel
        </Button>
      </Box>
    </Paper>
  );
}
