import React, { useEffect, useState } from "react";
import "../dashboard/dashboard.css";
import {
  Button,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
} from "@mui/material";
import styled from "styled-components";
import axios from "axios";
import { useSnackbar } from "../../alert/SnackbarProvider";

const StyledButton = styled(Button)`
  && {
    border-radius: 16px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(220, 115, 76, 1);
    }
  }
`;

const StyledTypographyButton = styled(Typography)`
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
`;

const AddParent = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [memberManual, setMemberManual] = useState(false);

  const { openSnackbar } = useSnackbar();

  // const createParentInFlamelink = () => {
  //   console.log("createParentInFlamelink");
  // };

  //  useEffect(() => {
  //   createParentInFlamelink();
  // }, []);

  const handleCreateParent = async () => {
    console.log("handleCreateParent");
    console.log(firstName);
    console.log(lastName);
    console.log(email);
    console.log(phone);

    if (firstName === "" || lastName === "" || email === "" || phone === "") {
      alert("Please fill out all fields");
      return;
    }

    try {
      await axios.post("https://proxy.kindship.app/create-kindship-profile", {
        firstName: firstName,
        lastName: lastName,
        mobile_number: phone,
        email: email,
        memberManual,
      });
      openSnackbar("Created Parent Successfully");

      // Reset Form
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setMemberManual(false);
    } catch (err) {
      console.error("Error while creating Parent", err);
      openSnackbar("Error while creating Parent");
    }
  };

  return (
    <>
      <h1 className="title__content">Add Parent</h1>
      <br />
      <br />
      <TextField
        value={firstName}
        onChange={(e) => {
          setFirstName(e.target.value);
        }}
        id="outlined-basic"
        label="First Name"
        variant="outlined"
      />
      <br />
      <br />
      <TextField
        value={lastName}
        onChange={(e) => {
          setLastName(e.target.value);
        }}
        id="outlined-basic"
        label="Last Name"
        variant="outlined"
      />
      <br />
      <br />
      <TextField
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        id="outlined-basic"
        label="Email"
        variant="outlined"
      />
      <br />
      <br />
      <TextField
        value={phone}
        onChange={(e) => {
          setPhone(e.target.value);
        }}
        id="outlined-basic"
        label="Phone"
        variant="outlined"
      />

      <br />
      <br />

      <div>
        <FormControlLabel
          control={
            <Switch
              color="warning"
              id="member-manual"
              label="Member Manual"
              value={memberManual}
              onChange={(e) => {
                console.log(e.target.checked);
                setMemberManual(e.target.checked);
              }}
            />
          }
          label="Member Manual"
        />
      </div>
      <br />
      <StyledButton variant="contained" onClick={handleCreateParent}>
        <StyledTypographyButton variant="h3">
          Create Parent
        </StyledTypographyButton>
      </StyledButton>
    </>
  );
};

export default AddParent;
