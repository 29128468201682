// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';



const firebaseConfig = {
  apiKey: "AIzaSyCNqEw_LDa5FrS0RrPYIssxMpxbZmX4YUg",
  authDomain: "ablefinder-com.firebaseapp.com",
  databaseURL: "https://ablefinder-com.firebaseio.com",
  projectId: "ablefinder-com",
  storageBucket: "ablefinder-com.appspot.com",
  messagingSenderId: "313362582317",
  appId: "1:313362582317:web:1caadd55e7e2b3c27761a4",
  measurementId: "G-YMBBEL0RWB"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const db = getFirestore(app);

export { auth, provider, db, app };