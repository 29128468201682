

const checkKindshipEmail = (email) => {
    if (!email) {
        return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email follows the general email format
    if (!emailRegex.test(email)) {
        return false;
    }

    // Extract domain from the email address
    const [, domain] = email.split('@');

    // List of Google Workspace domains (replace with your own if needed)
    const googleWorkspaceDomains = [
        'kindship.com.au'
    ];

    // Check if the domain is a valid Google Workspace domain
    return googleWorkspaceDomains.includes(domain.toLowerCase());


}

export { checkKindshipEmail }

