import React, { useEffect, useState } from "react";
import {
  lighten,
  darken,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  Backdrop,
  Modal,
} from "@mui/material";
import { blueGrey, indigo } from "@mui/material/colors";
import UserCard from "../../common/components/user-card";
import styled from "@emotion/styled";
import axios from "axios";
import UserListData from "../../common/components/user-list";
import { MergeRounded } from "@mui/icons-material";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export default function IntercomMerge() {
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeId, setActiveId] = useState(null);

  const [leads, setLeads] = useState([]);
  const [leadQuery, setLeadQuery] = useState("");
  const [dropShow, setDropShow] = useState(false);
  const [leadisLoading, setLeadIsLoading] = useState(false);
  const [leadError, setLeadError] = useState(null);
  const [leadActiveId, setLeadActiveId] = useState(null);

  const [mergeResponse, setMergeResponse] = useState(null);
  const [isMergeLoading, setIsMergeLoading] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);

  const activeHandler = (id) => {
    setActiveId(id);
  };

  const leadActiveHandler = (id) => {
    setLeadActiveId(id);
  };

  const queryLeadHandler = (e) => {
    e.preventDefault();
    const value = leadQuery.trim();
    if (value === "") return;
    setDropShow(true);
    setLeadIsLoading(true);
    axios
      .post("https://flame.kindshipapp.net/api/v1/admin/users", {
        role: "lead",
        name: value,
      })
      .then(({ data }) => {
        setLeads(data.data.data);
        setLeadIsLoading(false);
      })
      .catch((error) => {
        setLeads(false);
        setLeadIsLoading(error);
      });
  };

  const mergeHandler = () => {
    setShowMergeModal(true);
    axios
      .post("https://flame.kindshipapp.net/api/v1/admin/merge", {
        lead: leadActiveId,
        user: activeId,
      })
      .then(({ data }) => {
        console.log(data.data);
        setIsMergeLoading(false);
        setMergeResponse(data.data);
      })
      .catch((error) => {
        setIsMergeLoading(false);
        setMergeResponse(error?.message);
      });
  };

  const queryHandler = (e) => {
    e.preventDefault();
    const value = query.trim();
    if (value === "") return;
    setIsShow(true);
    setIsLoading(true);
    axios
      .post("https://flame.kindshipapp.net/api/v1/admin/users", {
        role: "user",
        name: value,
      })
      .then(({ data }) => {
        setUsers(data.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          borderRadius: 1,
          paddingY: 4,
          boxShadow: null,
        }}
      >
        <Grid container spacing="2">
          <Grid item xs={6}>
            <Typography
              variant="h2"
              sx={{
                padding: 1,
                color: blueGrey[700],
                fontWeight: 600,
                letterSpacing: ".5px",
              }}
            >
              Users
            </Typography>
            <Box
              component="form"
              sx={{ padding: 1 }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                fullWidth
                spacing="2"
                sx={{ flexGrow: 1, marginBottom: 1 }}
              >
                <Grid item xs={9}>
                  <TextField
                    id="users-search"
                    label="Search Users"
                    onChange={(e) => setQuery(e.target.value)}
                    onClick={(e) => e.preventDefault()}
                    fullWidth
                    variant="filled"
                    sx={{ borderRadius: "4px 0 0 4px" }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    onClick={queryHandler}
                    disabled={query.trim() === ""}
                    sx={{
                      height: "100%",
                      boxShadow: "none",
                      borderRadius: "0 4px 4px 0",
                    }}
                    fullWidth
                    variant="contained"
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
              <UserCard user={users.filter((u) => u.id === activeId)[0]} />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Typography
              variant="h2"
              sx={{
                padding: 1,
                color: blueGrey[700],
                fontWeight: 600,
                letterSpacing: ".5px",
              }}
            >
              Leads
            </Typography>
            <Box
              component="form"
              sx={{ padding: 1 }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                fullWidth
                spacing="2"
                sx={{ flexGrow: 1, marginBottom: 1 }}
              >
                <Grid item xs={9}>
                  <TextField
                    id="users-leads"
                    label="Search Leads"
                    onChange={(e) => setLeadQuery(e.target.value)}
                    onClick={(e) => e.preventDefault()}
                    fullWidth
                    variant="filled"
                    sx={{ borderRadius: "4px 0 0 4px" }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    onClick={queryLeadHandler}
                    disabled={leadQuery.trim() === ""}
                    sx={{
                      height: "100%",
                      boxShadow: "none",
                      borderRadius: "0 4px 4px 0",
                    }}
                    fullWidth
                    variant="contained"
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
              <UserCard
                user={leads && leads.filter((u) => u.id === leadActiveId)[0]}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            justifyItems="center"
            alignItems="center"
            display="flex"
            marginY={3}
          >
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<MergeRounded />}
              onClick={mergeHandler}
              disabled={!activeId || !leadActiveId}
            >
              Merge Lead Into User
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShow}
      >
        <Box sx={{ width: "1200px", margin: "0 auto" }}>
          {
            <UserListData
              selected={activeHandler}
              rows={users}
              close={() => setIsShow(false)}
            />
          }
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={dropShow}
      >
        <Box sx={{ width: "1200px", margin: "0 auto" }}>
          {
            <UserListData
              selected={leadActiveHandler}
              rows={leads}
              close={() => setDropShow(false)}
            />
          }
        </Box>
      </Backdrop>
      <Backdrop open={showMergeModal}>
        <Box sx={{ width: "1000px", margin: "0 auto" }}>
          <Box backgroundColor="#fff" padding={4} borderRadius={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <UserCard user={mergeResponse} />
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, textAlign: "right" }}
            >
              <Button
                onClick={() => setShowMergeModal(false)}
                variant="contained"
              >
                Close
              </Button>
            </Typography>
          </Box>
        </Box>
      </Backdrop>
    </>
  );
}
