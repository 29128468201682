import React, { useEffect, useState } from 'react'
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material"
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Logout from '../../signinwithgoogle/logout'
import { useParams } from 'react-router-dom'
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase/firebase';
import { Button } from '@mui/material';
import styled from 'styled-components';
import "../dashboard/dashboard.css"
import SuccessAlert from '../alert/SuccessAlert';
import moment from 'moment';
import { useSnackbar } from '../../alert/SnackbarProvider';
import flame from '../../firebase/flamelink.cjs'
import axios from 'axios';


const StyledButton = styled(Button)`
&& {
  border-radius: 16px;
  transition: background-color 0.3s ease; 
  
  &:hover {
    background-color:rgba(220, 115, 76, 1);
  }
}
`;

const StyledTypographyButton = styled(Typography)`
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align :center;
`;

const ParentDashboardPage = () => {

  const { docId } = useParams();
  const [parentData, setParentData] = useState(null);
  const [childList, setChildList] = useState([]);
  const [currentChild, setCurrentChild] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const [openAddChild, setOpenAddChild] = useState(false);

  const [trigger, setTrigger] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    last_name: '',
    ndisNumber: ''
  });

  const { openSnackbar } = useSnackbar();


  // Plan Sharing Status
  const getPlanSharingStatus = (planStatus) => {
    if (planStatus === 'plan_verified' || planStatus === 'plan_success') {
      return 'Yes'
    } else {
      return 'No'
    }
  }

   // Dialogs
   const [openSwitchEmail, setOpenSwitchEmail] = useState(false);

   const handleClickOpenSwitchEmail = (childData) => {
    setCurrentChild(childData);
    setOpenSwitchEmail(true);
  };

  const handleClickCloseSwitchEmail = () => {
    setOpenSwitchEmail(false);
  };


  const createChildWithDetails = async () => {

    setOpenAddChild(false);

    // Need Parent Id
    if (!parentData) {
      console.log('No Parent Data Found');
      return;
    }

    if (!parentData.id) {
      console.log('No Parent Id Found');
      return;
    }

    if (!formData.firstName || !formData.last_name || !formData.ndisNumber) {
      console.log('Please fill all the fields');
      return;
    }

    let childData = {
      firstName: formData.firstName,
      last_name: formData.last_name,
      ndisNumber: formData.ndisNumber,
      userId: parentData.id,
    }

    // Create Child Api
    try {
    await axios.post('https://proxy.kindship.app/create-child', childData)
    openSnackbar('Created Child Successfully')

    setTrigger(current => !current);

    setFormData({
      firstName: '',
      last_name: '',
      ndisNumber: ''
    })
    
    } catch (error) {
      console.error('Error while creating child', error);
      openSnackbar('Error while creating child')
    }

  }

    //copy the email
    const handleCopyToClipboard = (emailText) => {
      navigator.clipboard.writeText(emailText)
        .then(() => {
          console.log('Text copied to clipboard', emailText);

          setShowSuccess(true);
          setTimeout(() => setShowSuccess(false), 3000);

        })
        .catch((err) => {
          console.error('Unable to copy text to clipboard', err);
        });
    };

    useEffect(() => {
      let collectionRef = collection(db, 'fl_content');

      // Create a query against the collection.
      const q = query(collectionRef,
        where('_fl_meta_.schema', '==', 'kindship_profile'),
        where('mobile_number', '==', '+61' + docId));

      // Execute Query
      getDocs(q).then((querySnapshot) => {
        if (querySnapshot.empty) {
          console.log('No matching documents.');
          return;
        }


        if (querySnapshot.size > 0) {
          let fetchedData = querySnapshot.docs[0]?.data();
          setParentData(fetchedData);

          console.log('Parent Data', fetchedData)

          // Get List of Childs for the Parent
          let childCollectionRef = collection(db, 'fl_content');

          // Create a query against the collection.
          const childQuery = query(childCollectionRef,
            where('_fl_meta_.schema', '==', 'childBio'),
            where('userId', '==', doc(db, 'fl_content', parentData?.id || 'abc')));

          // Execute Query
          getDocs(childQuery).then((childQuerySnapshot) => {
            console.log('ChildQuerySnapshot', childQuerySnapshot);
            if (childQuerySnapshot.empty) {
              console.log('No matching documents.');
              return;
            }
            if (childQuerySnapshot.size > 0) {
              let childData = [];
              childQuerySnapshot.docs.forEach((doc) => {
                console.log('Child Data', doc.data());
                childData.push(doc.data());
              });
              setChildList(childData);
            }
          })

          setLoading(false)
        }
      }).catch((error) => {
        console.log("Error getting documents: ", error);
      })
    }, [docId, parentData?.id, trigger])


      // Handle Sending Emails
  const handleSendSwitchEmail = async () => {

    console.log('Current Child', currentChild)
    // Get Current State of switch email
    setOpenSwitchEmail(false)
    // planSwitchEmail
    if(currentChild) {
      // Child Data is Available
      if(currentChild?.planSwitchEmail) {
        // Plan Swtich Email is already true

        // Make is false and true
        // Update the document
        const childDocRef = doc(db, 'fl_content', currentChild?.id)
        try {
          await updateDoc(childDocRef, {
            planSwitchEmail: false
          })

          await updateDoc(childDocRef, {
            planSwitchEmail: true
          })

          setCurrentChild(null)
         
          openSnackbar('Success - Email Sent')
          console.log('Plan Switch Email Updated')
        } catch(err) {
          openSnackbar('Failed - Unable to Sent Email')
          console.log(err)
        }
      } else {
        // Set plan Switch Email true
        // Update the document
        const childDocRef = doc(db, 'fl_content', currentChild?.id)
        try {
          await updateDoc(childDocRef, {
            planSwitchEmail: true
          })
          setCurrentChild(null)
          openSnackbar('Success - Email Sent')
          console.log('Plan Switch Email Updated')
        } catch(err) {
          openSnackbar('Failed - Unable to Sent Email')
          console.log(err)
        }
      }
    }
  }

    if (loading) return <h1 className="title__content">Loading...</h1>;

    return (
      <>
        {showSuccess && <SuccessAlert message="Copied to clipboard!" />}
        <div className="navbar__container">
          <div className="navbar__content">
            <h1 className="title__content">
              {parentData?.fullName?.split(' ')[0] || 'Unknown'}'s Dashboard
            </h1>
          </div>
          <div className="button__container">
            <StyledButton variant="contained" onClick={() => {
              setOpenAddChild(true);
            }}>
              <StyledTypographyButton variant="h3" >
                Add Child
              </StyledTypographyButton>
            </StyledButton>
            <StyledButton variant="contained" onClick={async () => {
              console.log('Logout')
              auth.signOut();
            }}>
              <StyledTypographyButton variant="h3" >
                Logout
              </StyledTypographyButton>
            </StyledButton >
          </div>
        </div>

        <div className="title__container">
          <h3 className="title__container__data">
            Parent :
          </h3>
        </div>

        <div className="two__container">
          <div className="left__container">
            <div className="left__container__box">
              <div className="name__container">
                <h4 className="left__side__fields">First Name</h4>
                <h4 className="right__side__fields__data">{parentData?.fullName?.split(' ')[0]}</h4>
              </div>
              <div className="line__container"></div>
              <div className="name__container  drop__name__container">
                <h4 className="left__side__fields">Last Name</h4>
                <h4 className="right__side__fields__data">{parentData?.fullName?.split(' ')[1]}</h4>
              </div>
              <div className="line__container"></div>
              <div className="name__container  drop__name__container">
                <h4 className="left__side__fields">Phone</h4>
                <h4 className="right__side__fields__data">{parentData?.mobile_number}</h4>
              </div>
              <div className="line__container"></div>
              <div className="name__container  drop__name__container">
                <h4 className="left__side__fields">Email</h4>
                <div className="email__styles__container" onClick={() => handleCopyToClipboard(parentData?.email)}>
                  <h4 className="right__side__fields__data" style={{ color: 'rgba(220, 115, 76, 1)' }}>
                    {parentData?.email}
                  </h4>
                  <FileCopyIcon sx={{
                    width: '16px',
                    height: '19px'
                  }}

                  />
                </div>
              </div>
              <div className="line__container"></div>
              <div className="name__container  drop__name__container">
                <h4 className="left__side__fields">Nickname</h4>
                <h4 className="right__side__fields__data">{parentData?.user_name}</h4>
              </div>
              <div className="line__container"></div>
              <div className="name__container  drop__name__container">
                <h4 className="left__side__fields">Plan Sharing status </h4>
                <h4 className="right__side__fields__data">{getPlanSharingStatus(parentData?.plan_status)}</h4>
              </div>
            </div>
          </div>



          <div className="right__container">
            <div className="right__container__box">
              <div className="name__container">

                {
                  childList.length === 0 && <h4 className="right__side__fields__data">No Child Found</h4>
                }

                {
                  childList.map((child, index) => (
                    <div key={index}>

                      <h4 className="left__side__fields">Child {index + 1} Details</h4>
                      <h4 className="right__side__fields__data">Name: {childList[index]?.firstName || 'No First Name'} {childList[index]?.last_name || 'No Last Name'}</h4>
                      <h4 className="right__side__fields__data">NDIS Number: {childList[index]?.ndisNumber || 'No NDIS Number'}</h4>
                      <br></br>
                      <StyledButton className="right__side__fields__data" variant="contained" onClick={() => {
                        handleClickOpenSwitchEmail(childList[index])
                       }}>
                        <StyledTypographyButton variant="h3" >
                          Send Switch Email
                        </StyledTypographyButton>
                      </StyledButton>
                      <div className="line__container"></div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>


        {/* Dialog */}
        <Dialog open={openAddChild} onClose={() => { }}>
          <DialogTitle>Add a New Child</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="firstName"
              label="First Name"
              type="text"
              fullWidth
              variant="standard"
              value={formData.firstName}
              onChange={(val) => {
                setFormData({
                  ...formData,
                  firstName: val.target.value
                })
              }}
              required
            />
            <TextField
              margin="dense"
              name="last_name"
              label="Last Name"
              type="text"
              fullWidth
              variant="standard"
              value={formData.last_name}
              onChange={(val) => {
                setFormData({
                  ...formData,
                  last_name: val.target.value
                })
              }}
              required
            />
            <TextField
              margin="dense"
              name="ndisNumber"
              label="NDIS Number"
              type="text"
              fullWidth
              variant="standard"
              value={formData.ndisNumber}
              onChange={(val) => {
                setFormData({
                  ...formData,
                  ndisNumber: val.target.value
                })
              }}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setOpenAddChild(false);
            }}>Cancel</Button>
            <Button onClick={createChildWithDetails}>Submit</Button>
          </DialogActions>
        </Dialog>


        <Dialog
       open={openSwitchEmail}
       onClose={handleClickCloseSwitchEmail}
       aria-labelledby="alert-dialog-title"
       aria-describedby="alert-dialog-description">
         {/* <DialogTitle id="alert-dialog-title">{"Send Email?"}</DialogTitle> */}
         <DialogContent>
        <DialogContentText id="alert-dialog-description">
        We will send a template to help the parent switch from their current plan manager to Kindship. Do you wish to proceed?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickCloseSwitchEmail}>Cancel</Button>
        <Button onClick={handleSendSwitchEmail} autoFocus>
        Yes, send email
        </Button>
      </DialogActions>
       </Dialog>
      </>
    )
  }

  export default ParentDashboardPage