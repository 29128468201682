import React from 'react'
import { auth } from '../firebase/firebase'
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';

const HomePage = () => {

  const StyledButton = styled(Button)`
&& {
  border-radius: 16px;
  transition: background-color 0.3s ease; 
  
  &:hover {
    background-color:rgba(220, 115, 76, 1);
  }
}
`;

const StyledTypographyButton = styled(Typography)`
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align :center;
`;



  return (
    <>
    <h3 style={{color: 'black'}}>You're Logged in as {auth?.currentUser?.email}</h3>
    <StyledButton variant="contained" onClick={async () => {
      console.log('Logout')
       auth.signOut();
    }}>
      <StyledTypographyButton variant="h3" >
        Logout
      </StyledTypographyButton>
    </StyledButton >
    </>
  )
}

export default HomePage