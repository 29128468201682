import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Chip, List, ListItem } from "@mui/material";
import CustomSwitch from "./switch";
import {
  AbcOutlined,
  EditNoteOutlined,
  EmailOutlined,
  FaceOutlined,
  MapOutlined,
  PersonOutline,
  PhoneOutlined,
} from "@mui/icons-material";
import axios from "axios";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const lists = [
  { id: "name", label: "Name", icon: <FaceOutlined /> },
  { id: "role", label: "Role", icon: <PersonOutline /> },
  { id: "phone", label: "Phone", icon: <PhoneOutlined /> },
  {
    id: "email",
    label: "Email",
    icon: <EmailOutlined />,
  },
  {
    id: "journey",
    label: "Journey",
    icon: <MapOutlined />,
    group: "custom_attributes",
  },
  {
    id: "nickname",
    label: "Nickname",
    icon: <AbcOutlined />,
    group: "custom_attributes",
  },
  {
    id: "plan_managed",
    label: "Plan Managed",
    icon: <EditNoteOutlined />,
    group: "custom_attributes",
  },
  {
    id: "ndis_plan_1",
    label: "Ndis Plan",
    icon: <EditNoteOutlined />,
    group: "custom_attributes",
  },
];

export default function UserCard({ user }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: "100%" }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[400] }} aria-label="avatar">
            {user?.name?.[0] || "U"}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={user?.name || "Jhon Doe"}
        subheader={user?.external_id}
      />

      <CardContent>
        <List>
          {user &&
            lists.map((key) => (
              <ListItem>
                <CustomSwitch
                  label={<Chip label={key.label} icon={key.icon} />}
                  value={
                    <Chip
                      variant="outlined"
                      label={
                        key?.group ? user[key.group][key.id] : user[key.id]
                      }
                    />
                  }
                />
              </ListItem>
            ))}
        </List>
      </CardContent>

      <CardActions disableSpacing>
        <Chip label={expanded ? "Show Less" : "Show More"} />
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>...</CardContent>
      </Collapse>
    </Card>
  );
}
