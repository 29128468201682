import { createTheme} from "@mui/material";


export const theme = createTheme ({
    palette :{
        primary :{
            main :'rgba(220, 115, 76, 1)',
            dark :"rgba(0, 0, 0, 1)",
            light :"rgba(94, 94, 94, 1)"
        }
    },
    typography :{
        h1:{
            fontSize :"26px",
            lineHeight:"39px",
            fontWeight:500
        },
        h2 :{
            fontSize :"18px",
            lineHeight:"27px"
        },
        h3:{
            fontSize :"16px",
            lineHeight:"24px"
        }
    }
})